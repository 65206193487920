import React from 'react';
import styled from 'styled-components';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Layout from '../components/Shared/layoutWhite';
import {graphql} from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import {Link as GatsbyLink} from 'gatsby';
import {serializers} from '../components/Shared/utils/serializers';

const MainSection = styled.main`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  width: 100vw;
  height: 100%;
`;

const AboutSection = styled.section`
  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    padding: 5px 8vmin 10vmin;
    position: relative;
    line-height: 1.4;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 5px 8vmin 10vmin;
    position: relative;
    line-height: 1.4;
  }
`;

const StaticImageDivStyle = {
    float: 'left',
    marginRight: '20px',
    marginBottom: '20px',
};

const AboutTextDiv = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.3em;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    width: 80vw;
    font-size: 18px;
  }
`;

const AboutMeHeadingStyled = styled.h1`
  font-weight: 500;
  font-size: 32px;
`;

const MainStyled = styled.main`
  margin: 10vw;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`;

const SectionStyled = styled.section``;

const MessageDiv = styled.div``;

const MainHeading = styled.h1`
  font-size: 3rem;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 2rem;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 2rem;
  }
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  position: relative;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid green;
  background-color: transparent;
  color: #663399;
`;

const SecondaryHeading = styled.h2`
  font-size: 1.75rem;
  text-rendering: optimizelegibility;
  letter-spacing: -0.015em;
  line-height: 1.125;
  font-weight: normal;
  margin-bottom: 1.5rem;
  max-width: 400px;

  & span {
    margin-left: 5px;
  }

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 1.2rem;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 1.2rem;
  }
`;

const About = ({data}) => {
    let _rawAboutBody;
    let authorImage;
    let authorImageSrc;

    if (data.sanityAbout !== null) {
        _rawAboutBody = data.sanityAbout._rawAboutBody;
        authorImage = data.sanityAbout.authorImage;
        authorImageSrc = getImage(authorImage.asset);
    }

    return (
        <Layout>
            {data.sanityAbout === null && (
                <MainStyled>
                    <SectionStyled>
                        <MessageDiv>
                            <MainHeading>I couldn't find the data for this page</MainHeading>
                            <SecondaryHeading>
                                The dev of this page sucks.
                                <span role='img' aria-label='Sad face emoji'>
                  😔
                </span>
                            </SecondaryHeading>
                            <GatsbyLinkStyled to='/'>alonso.wtf Homepage</GatsbyLinkStyled>
                        </MessageDiv>
                    </SectionStyled>
                </MainStyled>
            )}
            {data.sanityAbout !== null && (
                <MainSection>
                    <AboutSection>
                        <div>
                            <AboutMeHeadingStyled>About me</AboutMeHeadingStyled>
                        </div>
                        <GatsbyImage
                            image={authorImageSrc}
                            alt={authorImage.alt}
                            style={StaticImageDivStyle}
                        />
                        <AboutTextDiv>
                            <PortableText blocks={_rawAboutBody} serializers={serializers}/>
                        </AboutTextDiv>
                    </AboutSection>
                </MainSection>
            )}
        </Layout>
    );
};

export const aboutPageQuery = graphql`
    query aboutPageQuery {
        sanityAbout {
            _id
            authorImage {
                alt
                caption
                asset {
                    gatsbyImageData(placeholder: BLURRED, width: 300, height: 300)
                }
            }
            _rawAboutBody
        }
    }
`;

export default About;
